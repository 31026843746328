import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faCalendarAlt, faCreditCard, faUser, faLock, faPercent, faMoneyBillWave, faTruck, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { BillingDetails as BillingDetailsType, ValidationErrors } from '../types';
import './BillingDetails.css';

interface BillingDetailsProps {
  billingDetails: BillingDetailsType;
  handleInputChange: (field: string, value: string) => void;
  errors: ValidationErrors;
  calculatedRates: {
    usd?: {
      totalBaseCharge: number;
      totalNetCharge: number;
      totalDiscounts: number;
      totalDutiesAndTaxes: number;
      totalNetChargeWithDutiesAndTaxes: number;
    };
    commitDetails?: {
      label: string;
    };
  } | null;
}

const BillingDetails: React.FC<BillingDetailsProps> = ({ billingDetails, handleInputChange, errors, calculatedRates }) => {
  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount);
  };

  const renderChargeDetails = () => {
    if (!calculatedRates || !calculatedRates.usd) {
      return (
        <div className="loading-rates">
          <p>Calculating shipping rates...</p>
        </div>
      );
    }

    const { usd, commitDetails } = calculatedRates;

    return (
      <div className="charge-details">
        <h4><FontAwesomeIcon icon={faFileInvoiceDollar} className="charge-icon" /> Charge Details</h4>
        <div className="charge-grid">
          <div className="charge-item">
            <span><FontAwesomeIcon icon={faDollarSign} className="charge-icon" /> Total Base Charge:</span>
            <span>{formatCurrency(usd.totalBaseCharge, 'USD')}</span>
          </div>
          <div className="charge-item">
            <span><FontAwesomeIcon icon={faDollarSign} className="charge-icon" /> Total Net Charge:</span>
            <span>{formatCurrency(usd.totalNetCharge, 'USD')}</span>
          </div>
          <div className="charge-item">
            <span><FontAwesomeIcon icon={faMoneyBillWave} className="charge-icon" /> Total Discounts:</span>
            <span>{formatCurrency(usd.totalDiscounts, 'USD')}</span>
          </div>
          <div className="charge-item">
            <span><FontAwesomeIcon icon={faPercent} className="charge-icon" /> Total Duties and Taxes:</span>
            <span>{formatCurrency(usd.totalDutiesAndTaxes, 'USD')}</span>
          </div>
        </div>
        <div className="total-charge">
          <span>Total Net Charge with Duties and Taxes:</span>
          <span>{formatCurrency(usd.totalNetChargeWithDutiesAndTaxes, 'USD')}</span>
        </div>
        {commitDetails && (
          <div className="estimated-delivery">
            <FontAwesomeIcon icon={faCalendarAlt} className="charge-icon" />
            <span>Estimated Delivery: {commitDetails.label}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="BillingDetailsWrapper">
      <div className="BillingDetails">
        <div className="billing-wrapper">
          <div className="billing-content">
            <div className="billing-left">
              <div className="card-form">
                <h4>Payment Details</h4>
                <div className="form-group">
                  <FontAwesomeIcon icon={faCreditCard} className="input-icon" />
                  <input
                    type="text"
                    placeholder="Card Number"
                    value={billingDetails.cardNumber || ''}
                    onChange={(e) => handleInputChange('cardNumber', e.target.value)}
                  />
                  {errors.billingCardNumber && <span className="error">{errors.billingCardNumber}</span>}
                </div>
                <div className="form-group">
                  <FontAwesomeIcon icon={faUser} className="input-icon" />
                  <input
                    type="text"
                    placeholder="Card Holder Name"
                    value={billingDetails.cardHolderName || ''}
                    onChange={(e) => handleInputChange('cardHolderName', e.target.value)}
                  />
                  {errors.billingCardHolderName && <span className="error">{errors.billingCardHolderName}</span>}
                </div>
                <div className="card-details">
                  <div className="form-group">
                    <FontAwesomeIcon icon={faCalendarAlt} className="input-icon" />
                    <input
                      type="text"
                      placeholder="MM/YY"
                      value={billingDetails.expirationDate || ''}
                      onChange={(e) => handleInputChange('expirationDate', e.target.value)}
                    />
                    {errors.billingExpirationDate && <span className="error">{errors.billingExpirationDate}</span>}
                  </div>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faLock} className="input-icon" />
                    <input
                      type="text"
                      placeholder="CVV"
                      value={billingDetails.cvv || ''}
                      onChange={(e) => handleInputChange('cvv', e.target.value)}
                    />
                    {errors.billingCvv && <span className="error">{errors.billingCvv}</span>}
                  </div>
                </div>
              </div>
            </div>
            <div className="billing-right">
              {renderChargeDetails()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;