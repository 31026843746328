import React, { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { login } from '../../features/auth/authSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
import sidebarLogo from '../../assets/images/sidebar-logo.svg';

const validateEmail = (email: string): boolean => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!email || !password) {
      setError('Please enter both email and password.');
      setIsLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      setIsLoading(false);
      return;
    }

    try {
      await dispatch(login({ email, password })).unwrap();
      toast.success(
        <div>
          <strong>Login successful!</strong>
          <p>Welcome back to Armada Global Shipping.</p>
        </div>,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // Redirect to the dashboard after successful login
      navigate('/control-panel/dashboard', { replace: true });
    } catch (err) {
      setError((err as Error).message || 'Invalid email or password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__content">
          <div className="login__logo">
            <img src={sidebarLogo} alt="Armada Global Shipping" />
          </div>
          <h2 className="login__title">Welcome Back</h2>
          <p className="login__subtitle">Please enter your details to sign in</p>
          {error && <p className="login__error-message">{error}</p>}
          <form onSubmit={handleSubmit} className="login__form">
            <div className="login__form-group">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="login__input"
              />
              <label htmlFor="email" className="login__label">Email</label>
            </div>
            <div className="login__form-group">
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="login__input"
              />
              <label htmlFor="password" className="login__label">Password</label>
            </div>
            <div className="login__form-group login__form-group--checkbox">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="login__checkbox"
              />
              <label htmlFor="rememberMe" className="login__checkbox-label">Remember me</label>
            </div>
            <button type="submit" className="login__button" disabled={isLoading}>
              {isLoading ? (
                <><span className="login__button-text">Signing In</span><div className="login__button-loader"></div></>
              ) : (
                'Sign In'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;