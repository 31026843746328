import React, { useState } from 'react';
import { FaCopy, FaTimes, FaCheckCircle } from 'react-icons/fa';
import Lottie from 'react-lottie';
import deliveryTeamAnimation from '../../assets/animations/delivery-team.json';
import './SuccessBottomSheet.css';

interface SuccessBottomSheetProps {
  trackingNumber: string;
  onClose: () => void;
}

const SuccessBottomSheet: React.FC<SuccessBottomSheetProps> = ({ trackingNumber, onClose }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(trackingNumber);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: deliveryTeamAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="success-bottom-sheet-container">
      <div className="success-bottom-sheet">
        <button className="success-close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <div className="success-content">
          <div className="success-animation">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
          <div className="success-text">
            <h3>Order Created Successfully!</h3>
            <p>Your order has been created and is now being processed.</p>
            <div className="tracking-number-box">
              <span>Tracking Number:</span>
              <div className="tracking-number-content">
                <span className="tracking-number">{trackingNumber}</span>
                <button onClick={handleCopy} className="copy-btn">
                  {copied ? <FaCheckCircle /> : <FaCopy />}
                </button>
              </div>
            </div>
            {copied && <p className="copied-message">Copied to clipboard!</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessBottomSheet;