import React, { useState, useEffect } from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  steps: string[];
  currentStep: number;
}

const useStepTransition = (currentStep: number, totalSteps: number) => {
  const [animatedStep, setAnimatedStep] = useState(currentStep);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setAnimatedStep(prevStep => {
      const step = Math.min(Math.max(currentStep, 0), totalSteps - 1);
      return step;
    });
  }, [currentStep, totalSteps]);

  useEffect(() => {
    const progressPercentage = (animatedStep / (totalSteps - 1)) * 100;
    setProgress(progressPercentage);
  }, [animatedStep, totalSteps]);

  return { animatedStep, progress };
};

const ProgressBar: React.FC<ProgressBarProps> = ({ steps, currentStep }) => {
  const { animatedStep, progress } = useStepTransition(currentStep, steps.length);

  return (
    <div className="progressbar">
      <div 
        className="ProgressBar" 
        role="progressbar" 
        aria-valuenow={currentStep + 1} 
        aria-valuemin={1} 
        aria-valuemax={steps.length}
        aria-label={`Step ${currentStep + 1} of ${steps.length}`}
      >
        <div className="progress-line" style={{ width: `${progress}%` }}></div>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`progress-step ${index === animatedStep ? 'active' : ''} ${index < animatedStep ? 'completed' : ''}`}
          >
            <div 
              className="step-indicator"
              title={`Step ${index + 1}: ${step}`}
              aria-label={`Step ${index + 1} ${index < animatedStep ? 'completed' : index === animatedStep ? 'current' : ''}`}
            >
              {index < animatedStep ? (
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
              ) : (
                <span className="step-number">{index + 1}</span>
              )}
            </div>
            <span className="step-name">{step}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;