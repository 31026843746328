import React from 'react';
import ErrorSummary from '../ErrorSummary/ErrorSummary';
import SenderDetails from '../SenderDetails/SenderDetails';
import RecipientDetails from '../RecipientDetails/RecipientDetails';
import PickupLocationForm from '../PickupLocationForm/PickupLocationForm';
import DropoffLocationForm from '../DropoffLocationForm/DropoffLocationForm';
import ShipmentDetails from '../ShipmentDetails/ShipmentDetails';
import BillingDetails from '../BillingDetails/BillingDetails';
import OrderConfirmation from '../OrderConfirmation/OrderConfirmation';
import { countries } from '../../../utils/countries';
import { OrderData, ValidationErrors, Location } from '../types';
import './StepContent.css';

interface StepContentProps {
  currentStep: number;
  orderData: OrderData;
  errors: ValidationErrors;
  handleInputChange: (step: keyof OrderData, field: string, value: string | File | null) => void;
  setOrderData: React.Dispatch<React.SetStateAction<OrderData>>;
  images: File[];
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  calculatedRates: any;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
}

const StepContent: React.FC<StepContentProps> = ({
  currentStep,
  orderData,
  errors,
  handleInputChange,
  setOrderData,
  images,
  setImages,
  calculatedRates,
  setErrors
}) => {
  const handlePickupLocationChange = (field: string, value: string | number) => {
    setOrderData(prevData => ({
      ...prevData,
      pickupLocation: {
        ...prevData.pickupLocation,
        [field]: value,
        country: 'Kuwait',
        countryCode: 'KW',
        stateOrProvinceCode: 'KW'
      }
    }));
  };

  const handleDropoffLocationChange = (field: string, value: string | number) => {
    if (field === 'country') {
      const selectedCountry = countries.find(c => c.code === value);
      setOrderData(prevData => ({
        ...prevData,
        dropoffLocation: {
          ...prevData.dropoffLocation,
          country: selectedCountry ? selectedCountry.name : '',
          countryCode: value as string,
          stateOrProvinceCode: ''
        }
      }));
    } else {
      setOrderData(prevData => ({
        ...prevData,
        dropoffLocation: {
          ...prevData.dropoffLocation,
          [field]: value,
        }
      }));
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length > 5) {
      setErrors(prevErrors => ({...prevErrors, imageUpload: 'You can only upload up to 5 images.'}));
      return;
    }
    setImages(files);
    setErrors(prevErrors => {
      const newErrors = {...prevErrors};
      delete newErrors.imageUpload;
      return newErrors;
    });
  };

  const renderStepContent = () => {
    let stepErrors: ValidationErrors = {};
    switch (currentStep) {
      case 0:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('sender')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {} as ValidationErrors);
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <SenderDetails
              senderDetails={orderData.senderDetails}
              handleInputChange={(field: string, value: string | File | null) => handleInputChange('senderDetails', field, value)}
              errors={errors}
            />
          </>
        );
      case 1:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('recipient')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {} as ValidationErrors);
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <RecipientDetails
              recipientDetails={orderData.recipientDetails}
              handleInputChange={(field: string, value: string | File | null) => handleInputChange('recipientDetails', field, value)}
              errors={errors}
            />
          </>
        );
      case 2:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('pickup')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {} as ValidationErrors);
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <PickupLocationForm 
              location={orderData.pickupLocation}
              onLocationChange={handlePickupLocationChange}
              errors={errors}
              otherLocation={orderData.dropoffLocation}
            />
          </>
        );
      case 3:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('dropoff') || key === 'locationState').reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {} as ValidationErrors);
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <DropoffLocationForm 
              location={orderData.dropoffLocation}
              onLocationChange={handleDropoffLocationChange}
              errors={errors}
              countries={countries}
              otherLocation={orderData.pickupLocation}
            />
          </>
        );
      case 4:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('shipment')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {} as ValidationErrors);
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <ShipmentDetails
              shipmentDetails={orderData.shipmentDetails}
              handleInputChange={(field: string, value: string | File | null) => handleInputChange('shipmentDetails', field, value)}
              errors={errors}
              handleImageUpload={handleImageUpload}
              images={images}
            />
          </>
        );
      case 5:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('billing') || key === 'rateCalculation').reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {} as ValidationErrors);
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <BillingDetails
              billingDetails={orderData.billingDetails}
              handleInputChange={(field: string, value: string | File | null) => handleInputChange('billingDetails', field, value)}
              errors={errors}
              calculatedRates={calculatedRates}
            />
          </>
        );
      case 6:
        return <OrderConfirmation orderData={{...orderData, dropoffLocation: orderData.carrierLocation}} calculatedAmount={calculatedRates?.usd?.totalNetChargeWithDutiesAndTaxes} />;
      default:
        return null;
    }
  };

  return (
    <div className="StepContent">
      <div className="content-wrapper">
        <div className="form-content">
          {renderStepContent()}
        </div>
      </div>
    </div>
  );
};

export default StepContent;