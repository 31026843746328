import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import './DropoffLocationForm.css';
import MapComponent from '../../MapComponent';
import { Location, ValidationErrors } from '../types';
import { geocodeAddress } from '../../../features/geocoding/geocodingSlice';
import { RootState, AppDispatch } from '../../../app/store';

interface DropoffLocationFormProps {
  location: Location;
  onLocationChange: (field: string, value: string | number) => void;
  errors: ValidationErrors;
  otherLocation: Location;
  countries: Array<{ code: string; name: string }>;
}

type Coordinates = [number, number];

// Define the hooks here
const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const DropoffLocationForm: React.FC<DropoffLocationFormProps> = ({ location, onLocationChange, errors, otherLocation, countries }) => {
  const dispatch = useAppDispatch();
  const { coordinates, loading, error } = useAppSelector((state: RootState) => state.geocoding);

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const lastAddressRef = useRef<string>('');

  const debounce = <F extends (...args: any[]) => any>(func: F, delay: number) => {
    return (...args: Parameters<F>) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const geocodeAddressCallback = useCallback(async () => {
    const address = `${location.streetName || ''} ${location.buildingNumber || ''}, ${location.city || ''}, ${location.country || ''}`.trim();
    try {
      await dispatch(geocodeAddress(address)).unwrap();
    } catch (err) {
      console.error('Failed to geocode address:', err);
    }
  }, [location.streetName, location.buildingNumber, location.city, location.country, dispatch]);

  const debouncedGeocodeAddress = useCallback(debounce(geocodeAddressCallback, 1000), [geocodeAddressCallback]);

  useEffect(() => {
    const address = `${location.streetName || ''} ${location.buildingNumber || ''}, ${location.city || ''}, ${location.country || ''}`.trim();
    
    // Only geocode if all required fields are present and the address differs from the last geocoded one.
    if (location.country && location.city && location.streetName && address !== lastAddressRef.current) {
      debouncedGeocodeAddress();
      lastAddressRef.current = address;
    }
  }, [location.country, location.city, location.streetName, location.buildingNumber, debouncedGeocodeAddress]);

  useEffect(() => {
    if (coordinates) {
      onLocationChange('latitude', coordinates.latitude);
      onLocationChange('longitude', coordinates.longitude);
    }
  }, [coordinates, onLocationChange]);

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = countries.find(c => c.code === e.target.value);
    onLocationChange('country', selectedCountry ? selectedCountry.name : '');
    onLocationChange('countryCode', e.target.value);
    onLocationChange('stateOrProvinceCode', ''); // Reset state/province when country changes
  };

  const locationToCoordinates = (loc: Location): Coordinates => [loc.latitude, loc.longitude];

  return (
    <div className="DropoffLocationForm">
      <select
        value={location.countryCode || ''}
        onChange={handleCountryChange}
      >
        <option value="">Select Country</option>
        {countries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>
      {errors.dropoffCountry && <span className="error">{errors.dropoffCountry}</span>}
      
      <input
        type="text"
        placeholder="Street Name"
        value={location.streetName || ''}
        onChange={(e) => onLocationChange('streetName', e.target.value)}
      />
      <input
        type="text"
        placeholder="Building Number"
        value={location.buildingNumber || ''}
        onChange={(e) => onLocationChange('buildingNumber', e.target.value)}
      />
      <input
        type="text"
        placeholder="City"
        value={location.city || ''}
        onChange={(e) => onLocationChange('city', e.target.value)}
      />
      <input
        type="text"
        placeholder="State/Province Code"
        value={location.stateOrProvinceCode || ''}
        onChange={(e) => onLocationChange('stateOrProvinceCode', e.target.value)}
      />
      <input
        type="text"
        placeholder="Postal Code"
        value={location.postalCode || ''}
        onChange={(e) => onLocationChange('postalCode', e.target.value)}
      />
      <input
        type="text"
        placeholder="District"
        value={location.district || ''}
        onChange={(e) => onLocationChange('district', e.target.value)}
      />
      {errors.dropoffAddress && <span className="error">{errors.dropoffAddress}</span>}
      {loading && <p></p>}
      {error && <p className="error">Error: {error}</p>}
      
      <div className="map-container">
        <MapComponent 
          pickupLocation={locationToCoordinates(otherLocation)}
          dropoffLocation={locationToCoordinates(location)}
        />
      </div>
    </div>
  );
};

export default DropoffLocationForm;