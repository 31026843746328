import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix for default marker icon
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

type Coordinates = [number, number] | null;

interface MapUpdaterProps {
  center: Coordinates;
}

function MapUpdater({ center }: MapUpdaterProps): null {
  const map = useMap();
  useEffect(() => {
    if (center && center[0] !== null && center[1] !== null) {
      map.setView(center, 13);
    }
  }, [center, map]);
  return null;
}

interface MapComponentProps {
  pickupLocation: Coordinates;
  dropoffLocation: Coordinates;
}

const MapComponent: React.FC<MapComponentProps> = ({ pickupLocation, dropoffLocation }) => {
  const defaultCenter: Coordinates = [0, 0];
  const center: Coordinates = (pickupLocation && pickupLocation[0] !== null && pickupLocation[1] !== null) ? pickupLocation : 
                 (dropoffLocation && dropoffLocation[0] !== null && dropoffLocation[1] !== null) ? dropoffLocation : 
                 defaultCenter;

  return (
    <MapContainer center={center as [number, number]} zoom={13} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {pickupLocation && pickupLocation[0] !== null && pickupLocation[1] !== null && (
        <Marker position={pickupLocation} />
      )}
      {dropoffLocation && dropoffLocation[0] !== null && dropoffLocation[1] !== null && (
        <Marker position={dropoffLocation} />
      )}
      <MapUpdater center={center} />
    </MapContainer>
  );
};

export default MapComponent;