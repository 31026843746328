import React, { useState, useEffect } from 'react';
import { useSpring, animated, AnimatedProps } from 'react-spring';
import Lottie from 'react-lottie';
import globalShippingAnimation from '../../assets/animations/wallet.json';
import './Wallet.css';

interface Transaction {
  id: number;
  type: 'Deposit' | 'Withdrawal';
  amount: number;
  date: string;
}

const Wallet: React.FC = () => {
  const [balance, setBalance] = useState<number>(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    // TODO: Fetch user's balance and transaction history from the backend
    // For now, we'll use dummy data
    setBalance(1000);
    setTransactions([
      { id: 1, type: 'Deposit', amount: 500, date: '2023-05-01' },
      { id: 2, type: 'Withdrawal', amount: -200, date: '2023-05-03' },
      { id: 3, type: 'Deposit', amount: 700, date: '2023-05-05' },
    ]);
  }, []);

  const handleTopUp = (): void => {
    // TODO: Implement top-up functionality
    alert('Top-up functionality will be implemented soon!');
  };

  const contentProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 500 },
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: globalShippingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimatedDiv = animated.div as React.FC<AnimatedProps<React.HTMLAttributes<HTMLDivElement>>>;

  return (
    <div className="Wallet">
      <header className="wallet-header">
        <div className="wallet-header-content">
          <h1>My Wallet</h1>
          <p>Manage your funds and view transaction history</p>
        </div>
        <div className="wallet-header-animation">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </header>

      <AnimatedDiv style={contentProps}>
        <div className="wallet-content">
          <div className="balance-section">
            <h2>Current Balance</h2>
            <p className="balance">${balance.toFixed(2)}</p>
            <button className="top-up-btn" onClick={handleTopUp}>
              <i className="fas fa-plus"></i> Top Up
            </button>
          </div>

          <div className="transaction-history">
            <h2>Transaction History</h2>
            <div className="transaction-table-container">
              <table className="transaction-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.date}</td>
                      <td>{transaction.type}</td>
                      <td className={transaction.amount >= 0 ? 'positive' : 'negative'}>
                        ${Math.abs(transaction.amount).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AnimatedDiv>
    </div>
  );
};

export default Wallet;