import React from 'react';
import Lottie from 'react-lottie';
import './InstructionsPanel.css';

// Import the animation JSON files
import * as senderAnimation from '../../../assets/animations/order-sender.json';
import * as recipientAnimation from '../../../assets/animations/order-recipient.json';
import * as pickupAnimation from '../../../assets/animations/armada-driver.json';
import * as dropoffAnimation from '../../../assets/animations/order-dropoff.json';
import * as shipmentDetailsAnimation from '../../../assets/animations/order-shipment-details.json';
import * as billingAnimation from '../../../assets/animations/order-billing.json';
import * as confirmAnimation from '../../../assets/animations/order-confirm.json';

interface Instruction {
  icon: any; // We're not using the icon here, but keeping it for consistency
  text: string;
  title: string;
}

interface InstructionsPanelProps {
  instruction: Instruction;
  step: number;
}

const InstructionsPanel: React.FC<InstructionsPanelProps> = ({ instruction, step }) => {
  const animationOptions = [
    senderAnimation,
    recipientAnimation,
    pickupAnimation,
    dropoffAnimation,
    shipmentDetailsAnimation,
    billingAnimation,
    confirmAnimation
  ];

  const getLottieOptions = (step: number) => ({
    loop: true,
    autoplay: true,
    animationData: animationOptions[step],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  });

  return (
    <div className="instructions-panel">
      <div className="lottie-animation">
        <Lottie options={getLottieOptions(step)} height={150} width={150} />
      </div>
      <div className="instruction-content">
        <h2>{instruction.title}</h2>
        <p>{instruction.text}</p>
      </div>
    </div>
  );
};

export default InstructionsPanel;