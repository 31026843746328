import React from 'react';
import { ValidationErrors } from '../types';
import './ErrorSummary.css';

interface ErrorSummaryProps {
  errors: ValidationErrors;
}

const ErrorSummary: React.FC<ErrorSummaryProps> = ({ errors }) => {
  const errorMessages = Object.values(errors).filter(error => error);

  if (errorMessages.length === 0) {
    return null;
  }

  return (
    <div className="ErrorSummary">
      <h3>Please correct the following errors:</h3>
      <ul>
        {errorMessages.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorSummary;