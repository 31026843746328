import React from 'react';
import OrderCreationContainer from './OrderCreationContainer/OrderCreationContainer';

// Define the Order type based on what we know about the order structure
export interface Order {
  id: string;
  createdAt: string;
  trackingNumber: string;
  recipientDetails: {
    firstName: string;
    lastName: string;
  };
  amount: number;
  status: number;
  userId: string;
  items: any[]; // You might want to define a more specific type for items
  shipmentDetails: any; // You might want to define a more specific type for shipment details
  pickupLocation: any; // You might want to define a more specific type for pickup location
  dropoffLocation: any; // You might want to define a more specific type for dropoff location
}

interface OrderCreationProps {
  onOrderCreated: (order: Order) => Promise<void>;
  onCancel: () => void;
}

const OrderCreation: React.FC<OrderCreationProps> = ({ onOrderCreated, onCancel }) => {
  return (
    <OrderCreationContainer
      onOrderCreated={onOrderCreated}
      onCancel={onCancel}
    />
  );
};

export default OrderCreation;