import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaChartLine, FaBox, FaShippingFast, FaBell, FaUser, FaCog, FaCode, FaBars, FaTimes, FaSignOutAlt, FaWallet } from 'react-icons/fa';
import { motion, AnimatePresence, CustomDomComponent } from 'framer-motion';
import './Sidebar.css';
import armadaLogo from '../../assets/images/sidebar-logo.svg';

interface SidebarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

interface SidebarItem {
  icon: React.ReactNode;
  title: string;
  id: string;
}

type MotionAside = CustomDomComponent<React.HTMLAttributes<HTMLElement>>;
type MotionImg = CustomDomComponent<React.ImgHTMLAttributes<HTMLImageElement>>;
type MotionSpan = CustomDomComponent<React.HTMLAttributes<HTMLSpanElement>>;
type MotionLi = CustomDomComponent<React.LiHTMLAttributes<HTMLLIElement>>;
type MotionDiv = CustomDomComponent<React.HTMLAttributes<HTMLDivElement>>;

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = () => {
    // Implement logout logic here
    // For example: clear local storage, reset user context, etc.
    navigate('/login');
  };

  const sidebarItems: SidebarItem[] = [
    { icon: <FaChartLine />, title: 'Dashboard', id: 'dashboard' },
    { icon: <FaBox />, title: 'Orders', id: 'orders' },
    { icon: <FaShippingFast />, title: 'Tracking', id: 'tracking' },
    { icon: <FaWallet />, title: 'Wallet', id: 'wallet' },
    { icon: <FaBell />, title: 'Notifications', id: 'notifications' },
    { icon: <FaCode />, title: 'Integration', id: 'integration' },
    { icon: <FaUser />, title: 'Account', id: 'account' },
    { icon: <FaCog />, title: 'Settings', id: 'settings' },
  ];

  const sidebarVariants = {
    expanded: { width: '250px' },
    collapsed: { width: '80px' }
  };

  const MotionAside = motion.aside as MotionAside;
  const MotionImg = motion.img as MotionImg;
  const MotionSpan = motion.span as MotionSpan;
  const MotionLi = motion.li as MotionLi;
  const MotionDiv = motion.div as MotionDiv;

  return (
    <MotionAside
      className={`Sidebar ${isMobile ? 'mobile' : ''}`}
      initial={false}
      animate={isCollapsed ? 'collapsed' : 'expanded'}
      variants={sidebarVariants}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      <div className="sidebar-header">
        <AnimatePresence>
          {!isCollapsed && (
            <MotionImg
              key="logo"
              src={armadaLogo}
              alt="Armada Logo"
              className="logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            />
          )}
        </AnimatePresence>
        <button className="toggle-btn" onClick={toggleSidebar}>
          {isCollapsed ? <FaBars /> : <FaTimes />}
        </button>
      </div>
      <nav>
        <ul>
          {sidebarItems.map((item) => (
            <MotionLi key={item.id} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <NavLink
                to={`/control-panel/${item.id}`}
                className={({ isActive }) => isActive ? 'active' : ''}
                onClick={() => setActiveTab(item.id)}
              >
                <span className="icon">{item.icon}</span>
                <AnimatePresence>
                  {!isCollapsed && (
                    <MotionSpan
                      className="title"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      {item.title}
                    </MotionSpan>
                  )}
                </AnimatePresence>
              </NavLink>
            </MotionLi>
          ))}
        </ul>
      </nav>
      <MotionDiv className="logout-container" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
        <button className="logout-btn" onClick={handleLogout}>
          <FaSignOutAlt />
          <AnimatePresence>
            {!isCollapsed && (
              <MotionSpan
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                Logout
              </MotionSpan>
            )}
          </AnimatePresence>
        </button>
      </MotionDiv>
    </MotionAside>
  );
};

export default Sidebar;