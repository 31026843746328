import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { geocodingApi } from '../../api/api';

interface GeocodingState {
  coordinates: {
    latitude: number;
    longitude: number;
  } | null;
  loading: boolean;
  error: string | null;
}

const initialState: GeocodingState = {
  coordinates: null,
  loading: false,
  error: null,
};

export const geocodeAddress = createAsyncThunk(
  'geocoding/geocodeAddress',
  async (address: string) => {
    const response = await geocodingApi.geocodeAddress(address);
    return response.data;
  }
);

const geocodingSlice = createSlice({
  name: 'geocoding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(geocodeAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(geocodeAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.coordinates = action.payload;
      })
      .addCase(geocodeAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default geocodingSlice.reducer;