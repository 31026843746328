import React from 'react';
import Hero from '../components/Hero/Hero';
import PackageJourney from '../components/SubSection/SubSection';
import Services from '../components/Services/Services';
import Footer from '../components/Footer/Footer';
import '../styles/global.css';
import './Home.css';

const Home: React.FC = () => {
  return (
    <div className="home">
      <main>
        <Hero />
        <PackageJourney />
        <Services />
      </main>
      <Footer />
    </div>
  );
};

export default Home;