import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { accountApi } from '../../api/api';

interface Address {
  streetAddress: string;
  apartmentNumber?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

interface UserData {
  id?: string;
  email: string;
  name: string;
  address?: Address;
}

interface PasswordData {
  currentPassword: string;
  newPassword: string;
}

interface AccountState {
  user: UserData;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: AccountState = {
  user: {
    email: '',
    name: ''
  },
  status: 'idle',
  error: null
};

export const fetchAccountInfo = createAsyncThunk(
  'account/fetchAccountInfo',
  async () => {
    const response = await accountApi.getAccountInfo();
    return response.data;
  }
);

export const updateAccountInfo = createAsyncThunk(
  'account/updateAccountInfo',
  async (accountData: UserData) => {
    const response = await accountApi.updateAccountInfo(accountData);
    return response.data;
  }
);

export const changePassword = createAsyncThunk(
  'account/changePassword',
  async (passwordData: PasswordData) => {
    const response = await accountApi.changePassword(passwordData);
    return response.data;
  }
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccountInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchAccountInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch account information';
      })
      .addCase(updateAccountInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAccountInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(updateAccountInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update account information';
      })
      .addCase(changePassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to change password';
      });
  },
});

export default accountSlice.reducer;