import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountInfo, updateAccountInfo, changePassword } from '../../features/account/accountSlice';
import { AppDispatch, RootState } from '../../app/store';
import './AccountSection.css';

interface Passwords {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Address {
  streetAddress: string;
  apartmentNumber?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

interface UserData {
  name: string;
  email: string;
  address?: Address;
}

const AccountSection: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, status, error } = useSelector((state: RootState) => state.account);

  const [activeTab, setActiveTab] = useState<'personal' | 'password' | 'address'>('personal');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState<Address>({
    streetAddress: '',
    apartmentNumber: '',
    city: '',
    state: '',
    postalCode: '',
    country: ''
  });
  const [passwords, setPasswords] = useState<Passwords>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      if (user.address) {
        setAddress(user.address);
      }
    }
  }, [user]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const validateForm = (): boolean => {
    if (activeTab === 'personal') {
      if (!name || !email) {
        return false;
      }
      if (!/^\S+@\S+\.\S+$/.test(email)) {
        return false;
      }
    } else if (activeTab === 'address') {
      if (!address.streetAddress || !address.city || !address.state || !address.postalCode || !address.country) {
        return false;
      }
    } else if (activeTab === 'password') {
      if (!passwords.currentPassword || !passwords.newPassword || !passwords.confirmPassword) {
        return false;
      }
      if (passwords.newPassword !== passwords.confirmPassword) {
        return false;
      }
      if (passwords.newPassword.length < 8) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (activeTab === 'personal' || activeTab === 'address') {
      const userData: UserData = { name, email, address };
      dispatch(updateAccountInfo(userData));
    } else if (activeTab === 'password') {
      setShowConfirmModal(true);
    }
  };

  const handleConfirmPasswordChange = async () => {
    setShowConfirmModal(false);
    dispatch(changePassword({
      currentPassword: passwords.currentPassword,
      newPassword: passwords.newPassword
    }));
    setPasswords({ currentPassword: '', newPassword: '', confirmPassword: '' });
  };

  return (
    <div className="AccountSection">
      <h2>Account Management</h2>
      <div className="account-tabs">
        <button
          className={activeTab === 'personal' ? 'active' : ''}
          onClick={() => setActiveTab('personal')}
        >
          Personal Information
        </button>
        <button
          className={activeTab === 'password' ? 'active' : ''}
          onClick={() => setActiveTab('password')}
        >
          Change Password
        </button>
        <button
          className={activeTab === 'address' ? 'active' : ''}
          onClick={() => setActiveTab('address')}
        >
          Manage Address
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}

      <form onSubmit={handleSubmit}>
        {activeTab === 'personal' && (
          <div className="tab-content">
            <h3>Personal Information</h3>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleNameChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
          </div>
        )}

        {activeTab === 'password' && (
          <div className="tab-content">
            <h3>Change Password</h3>
            <div className="form-group">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={passwords.currentPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>
        )}

        {activeTab === 'address' && (
          <div className="tab-content">
            <h3>Manage Address</h3>
            <div className="form-group">
              <label htmlFor="streetAddress">Street Address</label>
              <input
                type="text"
                id="streetAddress"
                name="streetAddress"
                value={address.streetAddress}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="apartmentNumber">Apartment Number</label>
              <input
                type="text"
                id="apartmentNumber"
                name="apartmentNumber"
                value={address.apartmentNumber || ''}
                onChange={handleAddressChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={address.city}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={address.state}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="postalCode">Postal Code</label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={address.postalCode}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={address.country}
                onChange={handleAddressChange}
                required
              />
            </div>
          </div>
        )}

        <button type="submit" className="submit-btn" disabled={status === 'loading'}>
          {status === 'loading' ? 'Saving...' : 'Save Changes'}
        </button>
      </form>

      {showConfirmModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Password Change</h3>
            <p>Are you sure you want to change your password?</p>
            <div className="modal-actions">
              <button onClick={handleConfirmPasswordChange}>Yes, Change Password</button>
              <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSection;