import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './ControlPanel.css';
import { FaBox, FaShippingFast, FaClipboardList, FaSpinner, FaBell, FaUser } from 'react-icons/fa';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Sidebar from '../Sidebar/Sidebar';
import NotificationCenter from '../NotificationCenter/NotificationCenter';
import OrdersManagement from '../OrdersManagement/OrdersManagement';
import OrderCreation from '../OrderCreation/OrderCreation';
import TrackingCenter from '../TrackingCenter/TrackingCenter';
import TrackingDetails from '../TrackingCenter/TrackingDetails';
import AccountSection from '../AccountSection/AccountSection';
import SuccessBottomSheet from '../SuccessBottomSheet/SuccessBottomSheet';
import IntegrationOfficer from '../IntegrationOfficer/IntegrationOfficer';
import Wallet from '../Wallet/Wallet';
import { useUser } from '../../context/UserContext';

ChartJS.register(ArcElement, Tooltip, Legend);

interface QuickLink {
  icon: React.ReactNode;
  title: string;
  link: string;
  color: string;
}

interface OrderStatus {
  label: string;
  count: number;
  color: string;
}

interface Order {
  id: string;
  createdAt: string;
  trackingNumber: string;
  recipientDetails: {
    firstName: string;
    lastName: string;
  };
  amount: number;
  status: number;
  userId: string;
  items: any[];
  shipmentDetails: any;
  pickupLocation: any;
  dropoffLocation: any;
}

const ControlPanel: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('dashboard');
  const [animateCards, setAnimateCards] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showSuccessSheet, setShowSuccessSheet] = useState<boolean>(false);
  const [trackingNumber, setTrackingNumber] = useState<string>('');

  useEffect(() => {
    const loadData = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setAnimateCards(true);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load data. Please try again.');
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    setActiveTab(path || 'dashboard');
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleOrderCreated = async (order: Order): Promise<void> => {
    setTrackingNumber(order.trackingNumber);
    setShowSuccessSheet(true);
    navigate('/control-panel/orders');
  };

  const handleOrderCancel = () => {
    navigate('/control-panel/orders');
  };

  const quickLinks: QuickLink[] = [
    { icon: <FaBox />, title: 'Create Order', link: '/control-panel/orders/create', color: 'var(--primary-color)' },
    { icon: <FaShippingFast />, title: 'Track Shipment', link: '/control-panel/tracking', color: 'var(--secondary-color)' },
    { icon: <FaClipboardList />, title: 'Manage Orders', link: '/control-panel/orders', color: '#4a90e2' },
  ];

  const orderStatusData: OrderStatus[] = [
    { label: 'In Progress', count: 5, color: 'var(--primary-color)' },
    { label: 'Shipped', count: 12, color: 'var(--secondary-color)' },
    { label: 'Delivered', count: 8, color: '#4a90e2' },
  ];

  const chartData = {
    labels: orderStatusData.map(item => item.label),
    datasets: [
      {
        data: orderStatusData.map(item => item.count),
        backgroundColor: orderStatusData.map(item => item.color),
        hoverBackgroundColor: orderStatusData.map(item => item.color),
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.label || '';
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce((acc: number, data: number) => acc + data, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  const renderDashboard = () => (
    <div className="dashboard-default">
      <header className="dashboard-header">
        <h1 className="dashboard-title">Dashboard</h1>
        <div className="user-action-banner">
          <button className="action-button" onClick={() => navigate('/control-panel/notifications')}><FaBell /> Notifications</button>
          <button className="action-button" onClick={() => navigate('/control-panel/account')}><FaUser /> Profile</button>
        </div>
      </header>
      <section className={`quick-links ${animateCards ? 'animate' : ''}`}>
        <h2>Quick Actions</h2>
        <div className="cards">
          {quickLinks.map((link, index) => (
            <Link to={link.link} key={index} className="card" style={{ '--card-color': link.color } as React.CSSProperties}>
              {link.icon}
              <h3>{link.title}</h3>
            </Link>
          ))}
        </div>
      </section>
      <section className="dashboard-main">
        <div className="dashboard-column">
          <section className="activity-feed">
            <h2>Recent Activity</h2>
            <ul>
              <li>New order #12345 created</li>
              <li>Shipment #54321 delivered</li>
              <li>Customer feedback received for order #67890</li>
            </ul>
          </section>
        </div>
        <div className="dashboard-column">
          <section className="order-status-chart">
            <h2>Order Status</h2>
            <div className="chart-container">
              <div className="chart-wrapper">
                <Doughnut data={chartData} options={chartOptions} />
              </div>
              <div className="status-labels">
                {orderStatusData.map((item, index) => (
                  <div key={index} className="status-label-card" style={{ '--label-color': item.color } as React.CSSProperties}>
                    <div className="status-dot"></div>
                    <div className="status-info">
                      <span className="status-text">{item.label}</span>
                      <span className="status-count">{item.count}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );

  return (
    <div className={`ControlPanel ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
      <Sidebar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <main className={`dashboard ${activeTab !== 'dashboard' ? 'full-width' : ''}`}>
        <div className="dashboard-content">
          {isLoading ? (
            <div className="loading-spinner">
              <FaSpinner className="spinner" />
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="error-message">
              <p>Error: {error}</p>
              <button onClick={() => window.location.reload()}>Retry</button>
            </div>
          ) : (
            <Routes>
              <Route index element={renderDashboard()} />
              <Route path="dashboard" element={renderDashboard()} />
              <Route path="orders" element={<OrdersManagement />} />
              <Route path="orders/create" element={<OrderCreation onOrderCreated={handleOrderCreated} onCancel={handleOrderCancel} />} />
              <Route path="tracking" element={<TrackingCenter />} />
              <Route path="tracking/:trackingNumber" element={<TrackingDetails />} />
              <Route path="notifications" element={<NotificationCenter />} />
              <Route path="account" element={<AccountSection />} />
              <Route path="integration" element={<IntegrationOfficer />} />
              <Route path="wallet" element={<Wallet />} />
            </Routes>
          )}
        </div>
      </main>
      {showSuccessSheet && (
        <SuccessBottomSheet
          trackingNumber={trackingNumber}
          onClose={() => setShowSuccessSheet(false)}
        />
      )}
    </div>
  );
};

export default ControlPanel;