import React, { useRef } from 'react';
import './ShipmentDetails.css';
import { ShipmentDetails as ShipmentDetailsType, ValidationErrors } from '../types';

interface ShipmentDetailsProps {
  shipmentDetails: ShipmentDetailsType;
  handleInputChange: (field: string, value: string) => void;
  errors: ValidationErrors;
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  images: File[];
}

const ShipmentDetails: React.FC<ShipmentDetailsProps> = ({ 
  shipmentDetails, 
  handleInputChange, 
  errors, 
  handleImageUpload, 
  images 
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageUpload(e);
    if (errors.imageUpload && fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input if there's an error
    }
  };

  return (
    <div className="ShipmentDetails">
      <input
        type="text"
        placeholder="Shipment Name"
        value={shipmentDetails.name || ''}
        onChange={(e) => handleInputChange('name', e.target.value)}
      />
      {errors.shipmentName && <span className="error">{errors.shipmentName}</span>}
      <textarea
        placeholder="Shipment Description"
        value={shipmentDetails.description || ''}
        onChange={(e) => handleInputChange('description', e.target.value)}
      />
      <select
        value={shipmentDetails.packagingType || ''}
        onChange={(e) => handleInputChange('packagingType', e.target.value)}
      >
        <option value="">Select Packaging Type</option>
        <option value="FEDEX_PAK">Armada Pak</option>
        <option value="FEDEX_BOX">Armada Box</option>
        <option value="FEDEX_TUBE">Armada Tube</option>
        <option value="YOUR_PACKAGING">Your Packaging</option>
      </select>
      {errors.packagingType && <span className="error">{errors.packagingType}</span>}
      <div className="weight-input">
        <input
          type="number"
          placeholder="Weight"
          value={shipmentDetails.weight || ''}
          onChange={(e) => handleInputChange('weight', e.target.value)}
        />
        <span>KG</span>
      </div>
      {errors.weight && <span className="error">{errors.weight}</span>}
      <div className="dimensions">
        <input
          type="number"
          placeholder="Length (cm)"
          value={shipmentDetails.length || ''}
          onChange={(e) => handleInputChange('length', e.target.value)}
        />
        <input
          type="number"
          placeholder="Width (cm)"
          value={shipmentDetails.width || ''}
          onChange={(e) => handleInputChange('width', e.target.value)}
        />
        <input
          type="number"
          placeholder="Height (cm)"
          value={shipmentDetails.height || ''}
          onChange={(e) => handleInputChange('height', e.target.value)}
        />
      </div>
      {(errors.length || errors.width || errors.height) && 
        <span className="error">All dimensions are required</span>}
      <input
        type="text"
        placeholder="Commodity Description"
        value={shipmentDetails.commodityDescription || ''}
        onChange={(e) => handleInputChange('commodityDescription', e.target.value)}
      />
      {errors.commodityDescription && <span className="error">{errors.commodityDescription}</span>}
      <div className="value-input">
        <input
          type="number"
          placeholder="Commodity Value"
          value={shipmentDetails.commodityValue || ''}
          onChange={(e) => handleInputChange('commodityValue', e.target.value)}
        />
        <select
          value={shipmentDetails.currency || 'USD'}
          onChange={(e) => handleInputChange('currency', e.target.value)}
        >
          <option value="USD">USD</option>
          <option value="KWD">KWD</option>
        </select>
      </div>
      {errors.commodityValue && <span className="error">{errors.commodityValue}</span>}
      <div className="image-upload">
        <label htmlFor="image-upload">Upload Images (Max 5)</label>
        <input
          id="image-upload"
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
          max="5"
        />
        <p className="image-limit-message">You can upload up to 5 images</p>
        {errors.imageUpload && <span className="error">{errors.imageUpload}</span>}
      </div>
      <div className="image-preview">
        {images.map((image, index) => (
          <img key={index} src={URL.createObjectURL(image)} alt={`Preview ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default ShipmentDetails;