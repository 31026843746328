import React from 'react';
import './SubSection.css';
import Lottie, { Options } from 'react-lottie';
import animationData from '../../assets/animations/delivery-team.json';

const SubSection: React.FC = () => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <section className="sub-section">
      <div className="container">
        <div className="sub-section-container">
          <div className="sub-section-text">
            <h2 className="sub-section-title">Welcome to the New Global Experience</h2>
            <div className="sub-section-description">
              <p>Experience seamless global shipping with our innovative platform. From packing to delivery, we've streamlined every step of the process to ensure your packages reach their destination efficiently and securely.</p>
              <p>Our advanced tracking system keeps you informed at every stage, while our worldwide network ensures rapid and reliable delivery to any corner of the globe.</p>
            </div>
          </div>
          <div className="sub-section-animation">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubSection;