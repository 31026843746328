import React from 'react';
import Lottie, { Options } from 'react-lottie';
import globalDeliveryAnimation from '../../../assets/videos/global-delivery.json';
import './LoadingAnimation.css';

interface LoadingAnimationProps {
  isCalculatingRates: boolean;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ isCalculatingRates }) => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: globalDeliveryAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="LoadingAnimation">
      <div className="animation">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
      <p className="loading-text">
        {isCalculatingRates ? 'Calculating shipping rates...' : 'Creating your order...'}
      </p>
    </div>
  );
};

export default LoadingAnimation;