import React, { useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header/Header';
import Home from './pages/Home';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import ControlPanel from './components/ControlPanel/ControlPanel';
import OrdersManagement from './components/OrdersManagement/OrdersManagement';
import AccountSection from './components/AccountSection/AccountSection';
import NotificationCenter from './components/NotificationCenter/NotificationCenter';
import TrackingCenter from './components/TrackingCenter/TrackingCenter';
import TrackingDetails from './components/TrackingCenter/TrackingDetails';
import Wallet from './components/Wallet/Wallet';
import IntegrationOfficer from './components/IntegrationOfficer/IntegrationOfficer';
import OrderCreation from './components/OrderCreation/OrderCreation';
import ProtectedRoute from './components/ProtectedRoute';
import { UserProvider } from './context/UserContext';
import './styles/global.css';

const AppContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isControlPanelRoute = location.pathname.startsWith('/control-panel');

  const handleOrderCreated = useCallback(async (order: { id: string }): Promise<void> => {
    toast.success(`Order ${order.id} created successfully!`);
    navigate('/control-panel/orders');
  }, [navigate]);

  const handleOrderCancel = useCallback(() => {
    navigate('/control-panel/orders');
  }, [navigate]);

  return (
    <div className="App">
      {!isControlPanelRoute && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/tracking/:trackingNumber" element={<TrackingDetails />} />
        <Route path="/control-panel" element={<ControlPanel />}>
          <Route index element={<AccountSection />} />
          <Route path="orders" element={<OrdersManagement />} />
          <Route path="orders/create" element={
            <OrderCreation 
              onOrderCreated={handleOrderCreated} 
              onCancel={handleOrderCancel} 
            />
          } />
          <Route path="notifications" element={<NotificationCenter />} />
          <Route path="tracking" element={<TrackingCenter />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="integrations" element={<IntegrationOfficer />} />
          <Route path="*" element={<ControlPanel />} />
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

const App: React.FC = () => {
  return (
    <UserProvider>
      <Router>
        <AppContent />
      </Router>
    </UserProvider>
  );
}

export default App;