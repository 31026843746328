import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { PersonDetails, ValidationErrors } from '../types';
import './SenderDetails.css';

interface SenderDetailsProps {
  senderDetails: PersonDetails;
  handleInputChange: (field: string, value: string | File | null) => void;
  errors: ValidationErrors;
}

const SenderDetails: React.FC<SenderDetailsProps> = ({ senderDetails, handleInputChange, errors }) => {
  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          handleInputChange('idPicture', e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: {'image/*': []},
    multiple: false
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  const renderIdPicture = () => {
    if (typeof senderDetails.idPicture === 'string') {
      return <img src={senderDetails.idPicture} alt="ID Preview" className="id-preview" />;
    } else if (senderDetails.idPicture instanceof File) {
      return <p>File selected: {senderDetails.idPicture.name}</p>;
    } else {
      return <p>Drag 'n' drop your ID picture here, or click to select a file</p>;
    }
  };

  return (
    <div className="SenderDetails">
      <input
        type="text"
        placeholder="First Name"
        value={senderDetails.firstName || ''}
        onChange={(e) => handleInputChange('firstName', e.target.value)}
      />
      {errors.senderFirstName && <span className="error">{errors.senderFirstName}</span>}
      <input
        type="text"
        placeholder="Last Name"
        value={senderDetails.lastName || ''}
        onChange={(e) => handleInputChange('lastName', e.target.value)}
      />
      {errors.senderLastName && <span className="error">{errors.senderLastName}</span>}
      <input
        type="email"
        placeholder="Email"
        value={senderDetails.email || ''}
        onChange={(e) => handleInputChange('email', e.target.value)}
      />
      {errors.senderEmail && <span className="error">{errors.senderEmail}</span>}
      <input
        type="tel"
        placeholder="Phone Number"
        value={senderDetails.PhoneNumber || ''}
        onChange={(e) => handleInputChange('PhoneNumber', e.target.value)}
      />
      {errors.senderPhoneNumber && <span className="error">{errors.senderPhoneNumber}</span>}
      
      <label htmlFor="senderIdType">ID Type</label>
      <select
        id="senderIdType"
        value={senderDetails.idType || ''}
        onChange={(e) => handleInputChange('idType', e.target.value)}
        className="id-type-select"
      >
        <option value="">Select ID Type</option>
        <option value="passport">Passport</option>
        <option value="driverLicense">Driver's License</option>
        <option value="nationalId">National ID</option>
      </select>
      {errors.senderIdType && <span className="error">{errors.senderIdType}</span>}
      
      <label className="id-upload-label">ID Picture</label>
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {renderIdPicture()}
      </div>
      {errors.senderIdPicture && <span className="error">{errors.senderIdPicture}</span>}
    </div>
  );
};

export default SenderDetails;