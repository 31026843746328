import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import globalShippingAnimation from '../../assets/animations/truck-delivery-service.json';
import OrderDetails from '../OrderDetails/OrderDetails';
import { orderApi } from '../../api/api';
import './OrdersManagement.css';

enum OrderStatus {
  Pending,
  Processing,
  Shipped,
  InTransit,
  Delivered,
  Cancelled
}

interface RecipientDetails {
  firstName: string;
  lastName: string;
}

interface Order {
  id: string;
  createdAt: string;
  trackingNumber: string;
  recipientDetails: RecipientDetails;
  amount: number;
  status: OrderStatus;
  userId: string;
  items: any[]; // Add more specific type if available
  shipmentDetails: any; // Add more specific type if available
  pickupLocation: any; // Add more specific type if available
  dropoffLocation: any; // Add more specific type if available
}

interface OrdersTableProps {
  orders: Order[];
  onOrderClick: (order: Order) => void;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ orders, onOrderClick }) => {
  return (
    <table className="order-management-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Tracking Number</th>
          <th>Recipient</th>
          <th>Amount ($)</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr key={order.id} onClick={() => onOrderClick(order)}>
            <td className="date">{new Date(order.createdAt).toLocaleDateString()}</td>
            <td className="tracking-number">{order.trackingNumber}</td>
            <td className="recipient">{`${order.recipientDetails.firstName} ${order.recipientDetails.lastName}`}</td>
            <td className="amount">{order.amount.toFixed(2)}</td>
            <td>
              <span className={`status ${OrderStatus[order.status].toLowerCase()}`}>
                {OrderStatus[order.status]}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const OrdersManagement: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const navigate = useNavigate();

  const fetchOrders = useCallback(async () => {
    setError(null);
    try {
      const response = await orderApi.getOrders();
      setOrders(response.data as Order[]);
    } catch (err) {
      setError('Failed to fetch orders. Please try again later.');
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleOrderClick = (order: Order) => {
    setSelectedOrder(order);
  };

  const handleCloseOrderDetails = () => {
    setSelectedOrder(null);
  };

  const handleCreateOrder = () => {
    navigate('/control-panel/orders/create');
  };

  const filteredOrders = orders.filter(order =>
    `${order.recipientDetails.firstName} ${order.recipientDetails.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.trackingNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    OrderStatus[order.status].toLowerCase().includes(searchTerm.toLowerCase()) ||
    new Date(order.createdAt).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.amount.toString().includes(searchTerm)
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: globalShippingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="OrdersManagement">
      <header className="om-header">
        <div className="om-header-content">
          <h1>Orders Management</h1>
          <p>Efficiently manage and track your orders in one place</p>
        </div>
        <div className="om-header-animation">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </header>

      <div className="content">
        {!selectedOrder && (
          <>
            <div className="instruction-container">
              <div className="instruction-text">
                <h3>Create a New Order</h3>
                <p>Start your order process by creating a new entry. Click the button below to get started.</p>
              </div>
              <button 
                className="create-order-btn" 
                onClick={handleCreateOrder}
              >
                <i className="fas fa-plus"></i> Create New Order
              </button>
            </div>
            <div className="search-container">
              <i className="fas fa-search search-icon"></i>
              <input
                type="text"
                placeholder="Search orders..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>
            {error && <div className="error">{error}</div>}
            <div className="orders-container">
              <OrdersTable orders={filteredOrders} onOrderClick={handleOrderClick} />
            </div>
          </>
        )}
        {selectedOrder && (
          <OrderDetails order={selectedOrder} onClose={handleCloseOrderDetails} />
        )}
      </div>
    </div>
  );
};

export default OrdersManagement;