import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ShipmentState {
  trackingNumber: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: ShipmentState = {
  trackingNumber: '',
  status: 'idle',
};

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    setTrackingNumber: (state, action: PayloadAction<string>) => {
      state.trackingNumber = action.payload;
    },
    setStatus: (state, action: PayloadAction<ShipmentState['status']>) => {
      state.status = action.payload;
    },
  },
});

export const { setTrackingNumber, setStatus } = shipmentSlice.actions;

export default shipmentSlice.reducer;