import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './TrackingDetails.css';
import { orderApi } from '../../api/api';
import { motion, AnimatePresence, CustomDomComponent } from 'framer-motion';
import { FaBox, FaTruck, FaMapMarkerAlt, FaCalendarAlt, FaInfoCircle, FaMoon, FaSun } from 'react-icons/fa';
import Lottie from 'react-lottie';
import deliveryBoyAnimation from '../../assets/videos/delivery-boy.json';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

interface TrackingEvent {
  date: string;
  time: string;
  location: string;
  status: string;
  description: string;
}

interface TrackingDetails {
  id?: string;
  orderId: string;
  status: string;
  origin: string;
  destination: string;
  currentLocation?: {
    lat: number;
    lng: number;
    address: string;
  };
  events: TrackingEvent[];
  weight: number;
  dimensions: string;
  serviceType: string;
  estimatedDelivery: string;
}

type MotionDiv = CustomDomComponent<React.HTMLAttributes<HTMLDivElement>>;
type MotionLi = CustomDomComponent<React.LiHTMLAttributes<HTMLLIElement>>;

const TrackingDetails: React.FC = () => {
  const { trackingNumber } = useParams<{ trackingNumber: string }>();
  const [trackingDetails, setTrackingDetails] = useState<TrackingDetails | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const mapRef = useRef<any>(null);

  useEffect(() => {
    const fetchTrackingDetails = async () => {
      try {
        const response = await orderApi.trackOrder(trackingNumber!);
        setTrackingDetails(response.data);
      } catch (err) {
        setError('An error occurred while fetching the tracking information. Please try again later.');
        console.error('Error fetching tracking data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrackingDetails();
  }, [trackingNumber]);

  useEffect(() => {
    if (trackingDetails && mapRef.current && trackingDetails.currentLocation) {
      const { lat, lng } = trackingDetails.currentLocation;
      mapRef.current.setView([lat, lng], 13);
    }
  }, [trackingDetails]);

  const formatDateTime = (date: string, time: string): string => {
    return `${date} ${time}`;
  };

  const getProgressPercentage = (): number => {
    if (!trackingDetails || !trackingDetails.events || trackingDetails.events.length === 0) {
      return 0;
    }
    const totalSteps = 5;
    const currentStep = trackingDetails.events.length;
    return Math.min((currentStep / totalSteps) * 100, 100);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: deliveryBoyAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  };

  const MotionDiv = motion.div as MotionDiv;
  const MotionLi = motion.li as MotionLi;

  if (isLoading) {
    return (
      <div className={`tracking-details tracking-details__loading ${darkMode ? 'dark-mode' : ''}`}>
        <Lottie options={defaultOptions} height={200} width={200} />
        <p>Loading tracking information...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`tracking-details tracking-details__error ${darkMode ? 'dark-mode' : ''}`}>
        <h2 className="tracking-details__error-title">Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  const defaultMapCenter = [0, 0];
  const mapCenter = trackingDetails?.currentLocation
    ? [trackingDetails.currentLocation.lat, trackingDetails.currentLocation.lng]
    : defaultMapCenter;

  return (
    <MotionDiv 
      className={`tracking-details ${darkMode ? 'dark-mode' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <header className="tracking-details__header">
        <h1 className="tracking-details__title">Tracking Details</h1>
        <p className="tracking-details__tracking-number">
          <FaBox className="tracking-details__icon" /> {trackingNumber}
        </p>
        <div className="tracking-details__actions">
          <button onClick={toggleDarkMode} className="tracking-details__action-btn">
            {darkMode ? <FaSun /> : <FaMoon />}
          </button>
        </div>
      </header>
      {trackingDetails && (
        <div className="tracking-details__content">
          <div className="tracking-details__summary">
            <div className="tracking-details__summary-item">
              <span className="tracking-details__summary-label">Status:</span>
              <span className="tracking-details__summary-value">{trackingDetails.status}</span>
            </div>
            <div className="tracking-details__summary-item">
              <span className="tracking-details__summary-label">Origin:</span>
              <span className="tracking-details__summary-value">{trackingDetails.origin}</span>
            </div>
            <div className="tracking-details__summary-item">
              <span className="tracking-details__summary-label">Destination:</span>
              <span className="tracking-details__summary-value">{trackingDetails.destination}</span>
            </div>
          </div>
          <div className="tracking-details__progress-bar-container">
            <MotionDiv 
              style={{
                width: `${getProgressPercentage()}%`,
              }}
              initial={{ width: 0 }}
              animate={{ width: `${getProgressPercentage()}%` }}
              transition={{ duration: 1, ease: "easeInOut" }}
              className="tracking-details__progress-bar"
            />
          </div>
          <div className="tracking-details__main-content">
            <div className="tracking-details__map">
              <MapContainer center={mapCenter as [number, number]} zoom={2} style={{ height: '300px', width: '100%' }} ref={mapRef}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {trackingDetails.currentLocation && (
                  <Marker position={[trackingDetails.currentLocation.lat, trackingDetails.currentLocation.lng]}>
                    <Popup>
                      Current location: {trackingDetails.currentLocation.address}
                    </Popup>
                  </Marker>
                )}
              </MapContainer>
            </div>
            <div className="tracking-details__timeline">
              <h3>Tracking Events</h3>
              {trackingDetails.events && trackingDetails.events.length > 0 ? (
                <ul className="tracking-details__event-list">
                  {trackingDetails.events.map((event, index) => (
                    <MotionLi 
                      key={index} 
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      whileHover={{ scale: 1.05 }}
                      className="tracking-details__event-item"
                    >
                      <div className="tracking-details__event-marker"></div>
                      <div className="tracking-details__event-content">
                        <p className="tracking-details__event-date">
                          <FaCalendarAlt className="tracking-details__icon" /> {formatDateTime(event.date, event.time)}
                        </p>
                        <p className="tracking-details__event-location">
                          <FaMapMarkerAlt className="tracking-details__icon" /> {event.location}
                        </p>
                        <p className="tracking-details__event-status">
                          <FaTruck className="tracking-details__icon" /> {event.status}
                        </p>
                        <p className="tracking-details__event-description">{event.description}</p>
                      </div>
                    </MotionLi>
                  ))}
                </ul>
              ) : (
                <p>No tracking events available.</p>
              )}
            </div>
          </div>
          <div className="tracking-details__additional-info">
            <button onClick={() => setShowDetails(!showDetails)} className="tracking-details__toggle-btn">
              <FaInfoCircle /> {showDetails ? 'Hide' : 'Show'} Shipment Details
            </button>
            <AnimatePresence>
              {showDetails && (
                <MotionDiv
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="tracking-details__shipment-details"
                >
                  <p><strong>Weight:</strong> {trackingDetails.weight} kg</p>
                  <p><strong>Dimensions:</strong> {trackingDetails.dimensions}</p>
                  <p><strong>Service Type:</strong> {trackingDetails.serviceType}</p>
                  <p><strong>Estimated Delivery:</strong> {trackingDetails.estimatedDelivery}</p>
                </MotionDiv>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
    </MotionDiv>
  );
};

export default TrackingDetails;