import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import notificationAnimation from '../../assets/animations/notification-bell.json';
import { fetchNotifications, markNotificationAsRead } from '../../features/notifications/notificationSlice';
import { RootState, AppDispatch } from '../../app/store';
import './NotificationCenter.css';

const NotificationStatus: { [key: number]: string } = {
  0: 'Unread',
  1: 'Read'
};

interface Notification {
  id: string;
  title: string;
  message: string;
  createdAt: string;
  isRead: boolean;
  orderId?: string;
}

interface NotificationsTableProps {
  notifications: Notification[];
  onNotificationClick: (notification: Notification) => void;
}

const NotificationsTable: React.FC<NotificationsTableProps> = ({ notifications, onNotificationClick }) => {
  return (
    <table className="notification-center-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Title</th>
          <th>Message</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {notifications.map((notification) => (
          <tr key={notification.id} onClick={() => onNotificationClick(notification)}>
            <td className="date">{new Date(notification.createdAt).toLocaleDateString()}</td>
            <td className="title">{notification.title}</td>
            <td className="message">{notification.message}</td>
            <td>
              <span className={`status ${NotificationStatus[notification.isRead ? 1 : 0].toLowerCase()}`}>
                {NotificationStatus[notification.isRead ? 1 : 0]}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const NotificationCenter: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { notifications, loading, error } = useSelector((state: RootState) => state.notifications);
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const handleNotificationClick = useCallback((notification: Notification) => {
    setSelectedNotification(notification);
    if (!notification.isRead) {
      dispatch(markNotificationAsRead(notification.id));
    }
  }, [dispatch]);

  const handleCloseNotificationDetails = () => {
    setSelectedNotification(null);
  };

  const filteredNotifications = notifications.filter(notification =>
    notification.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notification.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
    new Date(notification.createdAt).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notificationAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="NotificationCenter">
      <header className="nc-header">
        <div className="nc-header-content">
          <h1>Notification Center</h1>
          <p>Stay updated with the latest information and alerts</p>
        </div>
        <div className="nc-header-animation">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </header>

      <div className={`content ${selectedNotification ? 'hidden' : ''}`}>
        <div className="instruction-container">
          <div className="instruction-text">
            <h3>Manage Your Notifications</h3>
            <p>View and manage all your notifications in one place. Use the search bar to find specific notifications.</p>
          </div>
        </div>
        <div className="search-container">
          <i className="fas fa-search search-icon"></i>
          <input
            type="text"
            placeholder="Search notifications..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
        {error && <div className="error">{error}</div>}
        {loading ? (
          <div className="loading">Loading notifications...</div>
        ) : (
          <div className="notifications-container">
            <NotificationsTable notifications={filteredNotifications} onNotificationClick={handleNotificationClick} />
          </div>
        )}
      </div>
      <div className={`notification-details ${selectedNotification ? '' : 'hidden'}`}>
        {selectedNotification && (
          <div className="notification-details">
            <h3>{selectedNotification.title}</h3>
            <p>{selectedNotification.message}</p>
            <p><strong>Date:</strong> {new Date(selectedNotification.createdAt).toLocaleString()}</p>
            {selectedNotification.orderId && (
              <p><strong>Related Order ID:</strong> {selectedNotification.orderId}</p>
            )}
            <button onClick={handleCloseNotificationDetails}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationCenter;