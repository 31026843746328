import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="Footer">
      <div className="container">
        <div className="links">
          <div className="column">
            <h3>Help Center</h3>
            <ul>
              <li><a href="#customer-service">Customer Service</a></li>
              <li><a href="#contact-us">Contact Us</a></li>
              <li><a href="#faqs">FAQs</a></li>
            </ul>
          </div>
          <div className="column">
            <h3>Our Services</h3>
            <ul>
              <li><a href="#global-shipping">Global Shipping</a></li>
              <li><a href="#freight-forwarding">Freight Forwarding</a></li>
              <li><a href="#express-services">Express Services</a></li>
            </ul>
          </div>
          <div className="column">
            <h3>About Armada Global</h3>
            <ul>
              <li><a href="#about-us">About Us</a></li>
              <li><a href="#careers">Careers</a></li>
              <li><a href="#sustainability">Sustainability</a></li>
            </ul>
          </div>
          <div className="column">
            <h3>Connect with Us</h3>
            <ul className="social-links">
              <li><a href="#facebook">Facebook</a></li>
              <li><a href="#twitter">Twitter</a></li>
              <li><a href="#linkedin">LinkedIn</a></li>
            </ul>
          </div>
        </div>
        <div className="bottom">
          <p>&copy; 2023 Armada Global Shipping. All rights reserved.</p>
          <ul className="legal-links">
            <li><a href="#terms">Terms of Use</a></li>
            <li><a href="#privacy">Privacy Notice</a></li>
            <li><a href="#cookies">Cookies</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;