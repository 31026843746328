import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import ProgressBar from '../ProgressBar/ProgressBar';
import StepContent from '../StepContent/StepContent';
import NavigationButtons from '../NavigationButtons/NavigationButtons';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import InstructionsPanel from '../InstructionsPanel/InstructionsPanel';
import { steps, validateStep, calculateShippingRates, instructions } from '../orderCreationUtils';
import { useDispatch } from 'react-redux';
import { createOrder } from '../../../features/order/orderSlice';
import { OrderData, Location, PersonDetails, ShipmentDetails, BillingDetails } from '../types';
import { AppDispatch } from '../../../app/store';
import './OrderCreationContainer.css';

// Import the animation JSON file
import * as animationData from '../../../assets/animations/plane.json';

interface OrderCreationContainerProps {
  onOrderCreated: (order: any) => void;
  onCancel: () => void;
}

const initialOrderData: OrderData = {
  senderDetails: {
    firstName: '',
    lastName: '',
    email: '',
    PhoneNumber: '',
    idType: '',
    idPicture: null
  },
  recipientDetails: {
    firstName: '',
    lastName: '',
    email: '',
    PhoneNumber: '',
    idType: '',
    idPicture: null
  },
  pickupLocation: {
    useDetailedAddress: true,
    country: 'Kuwait',
    countryCode: 'KW',
    stateOrProvinceCode: 'KW',
    streetName: '',
    buildingNumber: '',
    city: '',
    postalCode: '',
    additionalNumber: '',
    district: '',
    latitude: 29.3759,
    longitude: 47.9774,
  },
  dropoffLocation: {
    useDetailedAddress: true,
    country: '',
    countryCode: '',
    stateOrProvinceCode: '',
    streetName: '',
    buildingNumber: '',
    city: '',
    postalCode: '',
    additionalNumber: '',
    district: '',
    latitude: 0,
    longitude: 0,
  },
  shipmentDetails: {
    name: '',
    weight: '',
    length: '',
    width: '',
    height: '',
    description: '',
    packagingType: '',
    commodityValue: '',
    currency: 'USD',
    commodityDescription: ''
  },
  billingDetails: {
    cardNumber: '',
    cardHolderName: '',
    expirationDate: '',
    cvv: '',
    amount: 0
  },
  carrierLocation: {
    useDetailedAddress: true,
    country: 'Kuwait',
    countryCode: 'KW',
    stateOrProvinceCode: 'KW',
    streetName: 'GROUND FLOOR، KHALEEJIA BLDG',
    buildingNumber: '',
    city: 'Kuwait City',
    postalCode: '',
    additionalNumber: '',
    district: '',
    latitude: 29.3759,
    longitude: 47.9774,
  }
};

const OrderCreationContainer: React.FC<OrderCreationContainerProps> = ({ onOrderCreated, onCancel }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [orderData, setOrderData] = useState<OrderData>(initialOrderData);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [images, setImages] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isCalculatingRates, setIsCalculatingRates] = useState<boolean>(false);
  const [calculatedRates, setCalculatedRates] = useState<any>(null);
  const orderCreationRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const scrollToTop = () => {
    if (orderCreationRef.current) {
      orderCreationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleNext = async () => {
    // dont uncomment validateStep(currentStep, orderData, setErrors)

    if (true) {
      if (currentStep === 4) {
        const success = await calculateShippingRates(orderData, setCalculatedRates, setErrors, setIsCalculatingRates);
        if (!success) return;
        
        if (calculatedRates && calculatedRates.usd) {
          setOrderData(prevData => ({
            ...prevData,
            billingDetails: {
              ...prevData.billingDetails,
              amount: calculatedRates.usd.totalNetChargeWithDutiesAndTaxes
            }
          }));
        }
      }
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
        scrollToTop();
      } 
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      scrollToTop();
    }
  };

  const handleSubmit = async () => {
    if (true) {
      setIsSubmitting(true);
      try {  
        const response = await dispatch(createOrder(orderData));
        onOrderCreated(response.payload);
      } catch (error) {
        console.error('Error creating order:', error);
        setErrors({ submit: 'Failed to create order. Please try again.' });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleInputChange = (step: keyof OrderData, field: string, value: string | File | null) => {
    setOrderData(prevData => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [field]: value,
      }
    }));
  };

  return (
    <div className="OrderCreationContainer" ref={orderCreationRef}>
      <header className="om-header">
        <div className="om-header-content">
          <h1>Orders Creation</h1>
          <p>Start a new shipment with few clicks</p>
        </div>
        <div className="om-header-animation">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </header>

      <button className="cancel-btn" onClick={onCancel}>
        <FontAwesomeIcon icon={faArrowLeft} /> Cancel
      </button>

      <ProgressBar steps={steps} currentStep={currentStep} />
      
      <div className="order-creation-layout">
        <div className="instructions-panel-wrapper">
          <InstructionsPanel instruction={instructions[currentStep]} step={currentStep} />
        </div>
        
        <div className="form-content-wrapper">
          {isSubmitting || isCalculatingRates ? (
            <div className="loading-container">
              <LoadingAnimation isCalculatingRates={isCalculatingRates} />
            </div>
          ) : (
            <div className="content-wrapper">
              <div className="form-content">
                <StepContent
                  currentStep={currentStep}
                  orderData={orderData}
                  errors={errors}
                  handleInputChange={handleInputChange}
                  setOrderData={setOrderData}
                  images={images}
                  setImages={setImages}
                  calculatedRates={calculatedRates}
                  setErrors={setErrors}
                />
              </div>
            </div>
          )}
          
          {!isSubmitting && !isCalculatingRates && (
            <NavigationButtons
              currentStep={currentStep}
              totalSteps={steps.length}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderCreationContainer;