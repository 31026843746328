import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './OrderDetails.css';

enum OrderStatus {
  Pending,
  Processing,
  Shipped,
  InTransit,
  Delivered,
  Cancelled
}

interface Location {
  streetName: string;
  city: string;
  country: string;
}

interface ShipmentDetails {
  weight: number;
  length: number;
  width: number;
  height: number;
}

interface RecipientDetails {
  firstName: string;
  lastName: string;
}

interface Order {
  id: string;
  createdAt: string;
  status: OrderStatus;
  recipientDetails: RecipientDetails;
  amount: number;
  shipmentDetails: ShipmentDetails;
  pickupLocation: Location;
  dropoffLocation: Location;
  trackingNumber: string;
}

interface OrderDetailsProps {
  order: Order | null;
  onClose: () => void;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ order, onClose }) => {
  if (!order) return null;

  const trackingLink = `${window.location.origin}/control-panel/tracking/${order.trackingNumber}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(trackingLink).then(() => {
      toast.success('Link copied to clipboard!', {
        // You can add more toast options here if needed
      });
    });
  };

  return (
    <div className="OrderDetails">
      <ToastContainer />
      <div className="order-details-container">
        <div className="order-details-header">
          <h2>Order Details</h2>
          <button className="back-button" onClick={onClose}>
            <i className="fas fa-arrow-left"></i> Back to Orders
          </button>
        </div>
        <div className="order-details-content">
          <section className="order-info">
            <h3>Order Information</h3>
            <p><strong>Order ID:</strong> #{order.id}</p>
            <p><strong>Date:</strong> {new Date(order.createdAt).toLocaleDateString()}</p>
            <p><strong>Status:</strong> 
              <span className={`status-badge ${OrderStatus[order.status].toLowerCase()}`}>
                {OrderStatus[order.status]}
              </span>
            </p>
            <p><strong>Recipient:</strong> {`${order.recipientDetails.firstName} ${order.recipientDetails.lastName}`}</p>
            <p><strong>Amount:</strong> ${order.amount.toFixed(2)}</p>
          </section>
          
          <section className="shipment-info">
            <h3>Shipment Details</h3>
            <p><strong>Weight:</strong> {order.shipmentDetails.weight} kg</p>
            <p><strong>Size:</strong> {order.shipmentDetails.length}x{order.shipmentDetails.width}x{order.shipmentDetails.height} cm</p>
            <div className="address-container">
              <div className="origin-address">
                <h4>Origin Address</h4>
                <p>{`${order.pickupLocation.streetName}, ${order.pickupLocation.city}, ${order.pickupLocation.country}`}</p>
              </div>
              <div className="destination-address">
                <h4>Destination Address</h4>
                <p>{`${order.dropoffLocation.streetName}, ${order.dropoffLocation.city}, ${order.dropoffLocation.country}`}</p>
              </div>
            </div>
          </section>
          
          <section className="tracking-info">
            <h3>Tracking Link</h3>
            <div className="tracking-link-container">
              <a href={trackingLink} className="tracking-link" target="_blank" rel="noopener noreferrer">
                {trackingLink}
              </a>
              <button className="copy-button" onClick={handleCopyLink}>
                <i className="fas fa-copy"></i> Copy Link
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;