import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBox, faCreditCard, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { shipmentApi, RateRequest, RateAddress, Weight, Dimensions } from '../../api/api';
import { validateSenderDetails, validateRecipientDetails, validateLocation, validateShipmentDetails, validateBillingDetails } from './validationUtils';
import { OrderData, ValidationErrors } from './types';

export const steps: string[] = [
  'Sender Details',
  'Recipient Details',
  'Pick-up Location',
  'Drop-off Location',
  'Shipment Details',
  'Billing',
  'Confirmation'
];

interface Instruction {
  icon: typeof faUser | typeof faMapMarkerAlt | typeof faBox | typeof faCreditCard | typeof faCheckCircle;
  text: string;
  title: string;
}

export const instructions: Instruction[] = [
  { icon: faUser, title: "Sender Information", text: "Enter the sender's full name, email, phone number, ID type, and upload an ID picture. This information is crucial for communication and tracking purposes." },
  { icon: faUser, title: "Recipient Information", text: "Provide the recipient's full name, email, phone number, ID type, and upload an ID picture. Ensure accuracy to avoid delivery issues." },
  { icon: faMapMarkerAlt, title: "Pick-up Details", text: "Specify the exact pick-up location in Kuwait, including address, city, and state. This is where the package will be collected." },
  { icon: faMapMarkerAlt, title: "Drop-off Details", text: "Enter the precise drop-off location, including address, city, state, and country. This is the final destination of your package." },
  { icon: faBox, title: "Package Information", text: "Describe your shipment, including its name, weight, and dimensions. Accurate details ensure proper handling and pricing." },
  { icon: faCreditCard, title: "Payment Details", text: "Review the calculated shipping rates and enter your payment details. We ensure secure processing of your financial information." },
  { icon: faCheckCircle, title: "Order Confirmation", text: "Review all the information you've entered. Make sure everything is correct before submitting your order." }
];

export const validateStep = (currentStep: number, orderData: OrderData, setErrors: (errors: ValidationErrors) => void): boolean => {
  let newErrors: ValidationErrors = {};
  // switch (currentStep) {
  //   case 0:
  //     newErrors = validateSenderDetails(orderData.senderDetails);
  //     break;
  //   case 1:
  //     newErrors = validateRecipientDetails(orderData.recipientDetails);
  //     break;
  //   case 2:
  //     newErrors = validateLocation(orderData.pickupLocation, 'pickup');
  //     if (orderData.pickupLocation.country !== 'Kuwait' || orderData.pickupLocation.countryCode !== 'KW') {
  //       newErrors.pickupCountry = "Pickup location must be in Kuwait.";
  //     }
  //     break;
  //   case 3:
  //     newErrors = validateLocation(orderData.dropoffLocation, 'dropoff');
  //     if (orderData.pickupLocation.city === orderData.dropoffLocation.city) {
  //       newErrors.locationState = "Pickup and dropoff locations must be in different cities.";
  //     }
  //     break;
  //   case 4:
  //     newErrors = validateShipmentDetails(orderData.shipmentDetails);
  //     break;
  //   case 5:
  //     newErrors = validateBillingDetails(orderData.billingDetails);
  //     break;
  //   default:
  //     break;
  // }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

export const calculateShippingRates = async (
  orderData: OrderData,
  setCalculatedRates: (rates: any) => void,
  setErrors: (errors: ValidationErrors) => void,
  setIsCalculatingRates: (isCalculating: boolean) => void
): Promise<boolean> => {
  setIsCalculatingRates(true);
  try {
    const rateRequest: RateRequest = {
      shipper: createRateAddress(orderData.pickupLocation),
      recipient: createRateAddress(orderData.dropoffLocation),
      packagingType: orderData.shipmentDetails.packagingType || 'YOUR_PACKAGING',
      weight: {
        units: 'KG',
        value: parseFloat(orderData.shipmentDetails.weight)
      },
      dimensions: {
        length: parseFloat(orderData.shipmentDetails.length),
        width: parseFloat(orderData.shipmentDetails.width),
        height: parseFloat(orderData.shipmentDetails.height),
        units: 'CM'
      },
      commodityDescription: orderData.shipmentDetails.description || 'General Merchandise',
      commodityValue: parseFloat(orderData.shipmentDetails.commodityValue) || 1,
      currency: orderData.shipmentDetails.currency || 'USD'
    };

    const response = await shipmentApi.calculateRates(rateRequest);
    setCalculatedRates(response.data);
    return true;
  } catch (error) {
    console.error('Error calculating shipping rates:', error);
    setErrors({ rateCalculation: 'Failed to calculate shipping rates. Please try again.' });
    return false;
  } finally {
    setIsCalculatingRates(false);
  }
};

function createRateAddress(location: any): RateAddress {
  return {
    streetLines: [location.streetName, location.buildingNumber].filter(Boolean),
    city: location.city,
    stateOrProvinceCode: location.stateOrProvinceCode,
    postalCode: location.postalCode,
    countryCode: location.countryCode,
    residential: location.residential || false
  };
}