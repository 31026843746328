import React, { useEffect } from 'react';
import { useSpring, animated, AnimatedProps } from 'react-spring';
import Lottie from 'react-lottie';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { fetchIntegrationData, generateApiKey, regenerateApiKey } from '../../features/integration/integrationSlice';
import integrationAnimation from '../../assets/animations/officer.json';
import APIPlayground from './APIPlayground/APIPlayground';
import IntegrationLanding from './IntegrationLanding';
import './IntegrationOfficer.css';

const IntegrationOfficer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: integrationData, loading, error } = useSelector((state: RootState) => state.integration);

  useEffect(() => {
    dispatch(fetchIntegrationData());
  }, [dispatch]);

  const handleGenerateApiKey = async () => {
    try {
      await dispatch(generateApiKey()).unwrap();
      toast.success('API key generated successfully!');
    } catch (error) {
      toast.error('Failed to generate API key. Please try again.');
    }
  };

  const handleRegenerateApiKey = async () => {
    try {
      await dispatch(regenerateApiKey()).unwrap();
      toast.success('API key regenerated successfully!');
    } catch (error) {
      toast.error('Failed to regenerate API key. Please try again.');
    }
  };

  const contentProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 500 },
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: integrationAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimatedDiv = animated.div as React.FC<AnimatedProps<React.HTMLAttributes<HTMLDivElement>>>;

  if (loading) {
    return (
      <div className="loading-container">
        <Lottie options={defaultOptions} height={200} width={200} />
        <p>Loading integration data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={() => dispatch(fetchIntegrationData())}>Try Again</button>
      </div>
    );
  }

  if (!integrationData || !integrationData.hasApiKey) {
    return <IntegrationLanding onGenerateApiKey={handleGenerateApiKey} />;
  }

  return (
    <div className="IntegrationOfficer">
      <header className="integration-header">
        <div className="integration-header-content">
          <h1>Integrations</h1>
          <p>Manage your API key, balance, and explore our API</p>
        </div>
        <div className="integration-header-animation">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </header>

      <AnimatedDiv style={contentProps}>
        <div className="integration-content">
          <div className="api-key-section">
            <h2>API Key Information</h2>
            <div className="api-key-info">
              <div className="api-key-display">
                <label>API Key:</label>
                <span className="api-key">{integrationData.apiKey}</span>
              </div>
              <div className="api-key-details">
                <p><strong>Issuance Date:</strong> {new Date(integrationData.issuanceDate).toLocaleDateString()}</p>
                <p><strong>Status:</strong> <span className={`status ${integrationData.isActive ? 'active' : 'inactive'}`}>{integrationData.isActive ? 'Active' : 'Inactive'}</span></p>
                <p><strong>Request Count:</strong> {integrationData.requestsCount}</p>
              </div>
              <div className="api-key-actions">
                <button className="regenerate-key-btn" onClick={handleRegenerateApiKey}>
                  <i className="fas fa-sync-alt"></i> Regenerate API Key
                </button>
              </div>
            </div>
          </div>

          <div className="balance-section">
            <h2>Current Balance</h2>
            <div className="balance-info">
              <p className="balance-amount">${integrationData.balance.toFixed(2)}</p>
              <p className="balance-status">Available for integrations</p>
              <button className="add-funds-btn">
                <i className="fas fa-plus"></i> Add Funds
              </button>
            </div>
          </div>

          <div className="documentation-section">
            <h2>API Documentation</h2>
            <p>Explore our comprehensive API documentation to integrate Armada Post into your systems seamlessly.</p>
            <a href="/api-docs" className="view-docs-btn">View Documentation</a>
          </div>

          <div className="api-playground-section">
            <h2>API Playground</h2>
            <p>Test our API endpoints and see real-time responses in our interactive API Playground.</p>
            <APIPlayground apiKey={integrationData.apiKey} />
          </div>
        </div>
      </AnimatedDiv>
    </div>
  );
};

export default IntegrationOfficer;