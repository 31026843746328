import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TrackingCenter.css';
import Lottie from 'react-lottie';
import globalShippingAnimation from '../../assets/animations/truck-delivery-service.json';

const TrackingCenter: React.FC = () => {
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const navigate = useNavigate();

  const handleTrack = () => {
    if (trackingNumber.trim()) {
      navigate(`/control-panel/tracking/${trackingNumber.trim()}`);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: globalShippingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="TrackingCenter">
      <header className="om-header">
        <div className="om-header-content">
          <h1>Track Your Shipment</h1>
          <p>Stay informed about your package's journey every step of the way</p>
        </div>
        <div className="om-header-animation">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </header>

      <div className="tracking-content">
        <div className="tracking-form-container">
          <div className="tracking-form">
            <input
              type="text"
              value={trackingNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTrackingNumber(e.target.value)}
              placeholder="Enter tracking number"
              className="tracking-input"
            />
            <button onClick={handleTrack} className="tracking-button">
              <i className="fas fa-search"></i> Track Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackingCenter;