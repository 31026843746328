import React, { createContext, useContext, useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../app/store';
import { login, logout, signup } from '../features/auth/authSlice';
import { fetchUnreadNotificationsCount } from '../features/notifications/notificationSlice';

interface UserContextType {
  isAuthenticated: boolean;
  user: any | null;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  logout: () => void;
  signup: (userData: any) => Promise<void>;
  loading: boolean;
  unreadNotificationsCount: number;
  fetchUnreadNotificationsCount: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, isAuthenticated, loading } = useSelector((state: RootState) => state.auth);
  const { unreadCount } = useSelector((state: RootState) => state.notifications);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUnreadNotificationsCount());
    }
  }, [isAuthenticated, dispatch]);

  const loginUser = async (credentials: { email: string; password: string }) => {
    await dispatch(login(credentials)).unwrap();
  };

  const logoutUser = () => {
    dispatch(logout());
  };

  const signupUser = async (userData: any) => {
    await dispatch(signup(userData)).unwrap();
  };

  const fetchNotifications = async () => {
    await dispatch(fetchUnreadNotificationsCount()).unwrap();
  };

  const value: UserContextType = {
    isAuthenticated,
    user,
    login: loginUser,
    logout: logoutUser,
    signup: signupUser,
    loading,
    unreadNotificationsCount: unreadCount,
    fetchUnreadNotificationsCount: fetchNotifications
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};