import React from 'react';
import './Services.css';
import oceanVideo from '../../assets/videos/global-shipping.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faShip, faLocationDot, faTruck, IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface Service {
  name: string;
  description: string;
  icon: IconDefinition;
}

const Services: React.FC = () => {
  const services: Service[] = [
    { 
      name: 'Pack', 
      description: "Our expert packing services ensure your items are securely wrapped and protected for their journey.",
      icon: faBox
    },
    { 
      name: 'Ship', 
      description: "Choose from our wide range of shipping options, including express, standard, and economy services.",
      icon: faShip
    },
    { 
      name: 'Track', 
      description: "Stay informed with our advanced tracking system. Get real-time updates on your shipment's location.",
      icon: faLocationDot
    },
    { 
      name: 'Receive', 
      description: "Experience hassle-free delivery with our convenient receiving options. We ensure timely arrivals.",
      icon: faTruck
    }
  ];

  return (
    <section className="services">
      <video autoPlay loop muted className="background-video">
        <source src={oceanVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="services-content">
        <h2>Comprehensive Shipping Solutions</h2>
        <p className="services-subtitle">Discover how Global handles your shipments with care and precision at every step of their journey.</p>
        
        <div className="services-grid">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <FontAwesomeIcon icon={service.icon} className="service-icon" />
              <h3>{service.name}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>

        <div className="shipping-info">
          <h3>Why Choose Global?</h3>
          <p>At Global, we pride ourselves on providing efficient, reliable, and secure shipping services. Our state-of-the-art tracking system ensures that you can monitor your package's journey from pickup to delivery.</p>
          <p>Our global network of partners allows us to deliver to over 200 countries, making international shipping easier than ever. We offer customized solutions for businesses and individuals alike.</p>
          <a href="/quote" className="cta-button">Get a Quote</a>
        </div>
      </div>
    </section>
  );
};

export default Services;