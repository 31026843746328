import { configureStore } from '@reduxjs/toolkit';
import shipmentSlice from '../features/shipment/shipmentSlice';
import authReducer from '../features/auth/authSlice';
import notificationReducer from '../features/notifications/notificationSlice';
import integrationReducer from '../features/integration/integrationSlice';
import geocodingReducer from '../features/geocoding/geocodingSlice';
import accountReducer from '../features/account/accountSlice';

export const store = configureStore({
  reducer: {
    shipment: shipmentSlice,
    auth: authReducer,
    notifications: notificationReducer,
    integration: integrationReducer,
    geocoding: geocodingReducer,
    account: accountReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;