import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { integrationApi } from '../../api/api';

interface IntegrationData {
  apiKey: string;
  issuanceDate: string;
  isActive: boolean;
  requestsCount: number;
  balance: number;
  hasApiKey: boolean;
}

interface ApiKeyResponse {
  apiKey: string;
}

interface ApiIntegrationResponse {
  apiKey?: string;
  issuanceDate?: string;
  isActive?: boolean;
  requestsCount?: number;
  balance?: number;
  hasApiKey?: boolean;
}

interface IntegrationState {
  data: IntegrationData | null;
  loading: boolean;
  error: string | null;
}

const initialState: IntegrationState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchIntegrationData = createAsyncThunk<IntegrationData, void, { rejectValue: string }>(
  'integration/fetchData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await integrationApi.getIntegrationData();
      const data: ApiIntegrationResponse = response.data;
      
      // Transform and validate the API response
      const transformedData: IntegrationData = {
        apiKey: data.apiKey || '',
        issuanceDate: data.issuanceDate || new Date().toISOString(),
        isActive: data.isActive || false,
        requestsCount: data.requestsCount || 0,
        balance: data.balance || 0,
        hasApiKey: data.hasApiKey || false,
      };

      return transformedData;
    } catch (error) {
      return rejectWithValue('Failed to fetch integration data');
    }
  }
);

export const generateApiKey = createAsyncThunk<ApiKeyResponse, void, { rejectValue: string }>(
  'integration/generateApiKey',
  async (_, { rejectWithValue }) => {
    try {
      const response = await integrationApi.createApiKey();
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to generate API key');
    }
  }
);

export const regenerateApiKey = createAsyncThunk<ApiKeyResponse, void, { rejectValue: string }>(
  'integration/regenerateApiKey',
  async (_, { rejectWithValue }) => {
    try {
      const response = await integrationApi.regenerateApiKey();
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to regenerate API key');
    }
  }
);

const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntegrationData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIntegrationData.fulfilled, (state, action: PayloadAction<IntegrationData>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchIntegrationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch integration data';
      })
      .addCase(generateApiKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateApiKey.fulfilled, (state, action: PayloadAction<ApiKeyResponse>) => {
        state.loading = false;
        if (state.data) {
          state.data.apiKey = action.payload.apiKey;
          state.data.issuanceDate = new Date().toISOString();
          state.data.hasApiKey = true;
        } else {
          state.data = {
            apiKey: action.payload.apiKey,
            issuanceDate: new Date().toISOString(),
            isActive: true,
            requestsCount: 0,
            balance: 0,
            hasApiKey: true,
          };
        }
      })
      .addCase(generateApiKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to generate API key';
      })
      .addCase(regenerateApiKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(regenerateApiKey.fulfilled, (state, action: PayloadAction<ApiKeyResponse>) => {
        state.loading = false;
        if (state.data) {
          state.data.apiKey = action.payload.apiKey;
          state.data.issuanceDate = new Date().toISOString();
        }
      })
      .addCase(regenerateApiKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to regenerate API key';
      });
  },
});

export default integrationSlice.reducer;