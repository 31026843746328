import React, { useState } from 'react';
import './APIPlayground.css';

interface APIPlaygroundProps {
  apiKey: string;
}

const APIPlayground: React.FC<APIPlaygroundProps> = ({ apiKey }) => {
  const [endpoint, setEndpoint] = useState<string>('');
  const [method, setMethod] = useState<string>('GET');
  const [requestBody, setRequestBody] = useState<string>('');
  const [response, setResponse] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically make an API call using the endpoint, method, requestBody, and apiKey
    // For this example, we'll just set a mock response
    setResponse(JSON.stringify({
      status: 'success',
      message: 'This is a mock response from the API playground.',
      endpoint,
      method,
      requestBody: requestBody ? JSON.parse(requestBody) : null,
    }, null, 2));
  };

  return (
    <div className="api-playground">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="endpoint">Endpoint:</label>
          <input
            type="text"
            id="endpoint"
            value={endpoint}
            onChange={(e) => setEndpoint(e.target.value)}
            placeholder="Enter API endpoint"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="method">Method:</label>
          <select
            id="method"
            value={method}
            onChange={(e) => setMethod(e.target.value)}
          >
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="requestBody">Request Body (JSON):</label>
          <textarea
            id="requestBody"
            value={requestBody}
            onChange={(e) => setRequestBody(e.target.value)}
            placeholder="Enter request body (if applicable)"
          />
        </div>
        <button type="submit">Send Request</button>
      </form>
      <div className="response-section">
        <h3>Response:</h3>
        <pre>{response}</pre>
      </div>
    </div>
  );
};

export default APIPlayground;