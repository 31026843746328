import React from 'react';
import Lottie from 'react-lottie';
import integrationAnimation from '../../assets/animations/officer.json';
import './IntegrationLanding.css';

interface IntegrationLandingProps {
  onGenerateApiKey: () => void;
}

const IntegrationLanding: React.FC<IntegrationLandingProps> = ({ onGenerateApiKey }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: integrationAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="integration-landing">
      <header className="integration-header">
        <div className="integration-header-content">
          <h1>Integrations</h1>
          <p>Streamline your shipping process with our powerful API</p>
        </div>
        <div className="integration-header-animation">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </header>

      <div className="landing-container">
        <div className="landing-content">
          <h2>Get Started with Our API</h2>
          <p>Integrate our robust shipping services directly into your application.</p>
          <p>Generate an API key now to unlock the full potential of our platform!</p>
          <button className="generate-key-btn" onClick={onGenerateApiKey}>
            Generate API Key
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntegrationLanding;