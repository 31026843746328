import React, { useState, FormEvent, ChangeEvent } from 'react';
import './Hero.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShip, faGlobe } from '@fortawesome/free-solid-svg-icons';
import shippingImage from '../../assets/images/shipping.png';

interface ShipmentDetails {
  from: string;
  to: string;
  weight: string;
}

const Hero: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'track' | 'ship'>('track');
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [shipmentDetails, setShipmentDetails] = useState<ShipmentDetails>({
    from: '',
    to: '',
    weight: '',
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (activeTab === 'track') {
      console.log('Tracking number:', trackingNumber);
    } else {
      console.log('Shipment details:', shipmentDetails);
    }
  };

  const handleShipmentDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setShipmentDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <section className="hero">
      <div className="hero-background">
        <img src={shippingImage} alt="Shipping" className="hero-image" />
        <div className="hero-overlay"></div>
      </div>
      <div className="hero-content">
        <h1>
          <span className="hero-title-accent">Global</span> Shipping
          <FontAwesomeIcon icon={faGlobe} className="hero-icon" />
        </h1>
        <p>Fast, Reliable Shipping to Over 220 Countries</p>
        <div className="form-container">
          <div className="form-tabs">
            <button
              className={`tab-btn ${activeTab === 'track' ? 'active' : ''}`}
              onClick={() => setActiveTab('track')}
            >
              <FontAwesomeIcon icon={faSearch} /> Track
            </button>
            <button
              className={`tab-btn ${activeTab === 'ship' ? 'active' : ''}`}
              onClick={() => setActiveTab('ship')}
            >
              <FontAwesomeIcon icon={faShip} /> Ship
            </button>
          </div>
          <form onSubmit={handleSubmit} className="form">
            {activeTab === 'track' ? (
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Enter tracking number"
                  value={trackingNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setTrackingNumber(e.target.value)}
                  className="form-input"
                />
                <button type="submit" className="btn btn-primary">
                  Track Shipment
                </button>
              </div>
            ) : (
              <>
                <div className="form-row">
                  <input
                    type="text"
                    name="from"
                    placeholder="From (City or Postal Code)"
                    value={shipmentDetails.from}
                    onChange={handleShipmentDetailsChange}
                    className="form-input"
                  />
                  <input
                    type="text"
                    name="to"
                    placeholder="To (City or Postal Code)"
                    value={shipmentDetails.to}
                    onChange={handleShipmentDetailsChange}
                    className="form-input"
                  />
                  <input
                    type="text"
                    name="weight"
                    placeholder="Weight (kg)"
                    value={shipmentDetails.weight}
                    onChange={handleShipmentDetailsChange}
                    className="form-input"
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Get Quote
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Hero;