import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  async (error: any) => {
    if (error.response?.status === 401) {
      // If unauthorized, redirect to login
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

interface Credentials {
  email: string;
  password: string;
}

interface UserData {
  id?: string;
  email: string;
  name: string;
}

interface OrderData {
  id?: string;
  userId: string;
  items: Array<{ productId: string; quantity: number }>;
}

interface TrackingEvent {
  date: string;
  time: string;
  location: string;
  status: string;
  description: string;
}

interface ShipmentData {
  id?: string;
  orderId: string;
  status: string;
  origin: string;
  destination: string;
  currentLocation: {
    lat: number;
    lng: number;
    address: string;
  };
  events: TrackingEvent[];
  weight: number;
  dimensions: string;
  serviceType: string;
  estimatedDelivery: string;
}

export interface RateAddress {
  streetLines: string[];
  city: string;
  stateOrProvinceCode: string;
  postalCode: string;
  countryCode: string;
  residential: boolean;
}

export interface Weight {
  units: string;
  value: number;
}

export interface Dimensions {
  length: number;
  width: number;
  height: number;
  units: string;
}

export interface RateRequest {
  shipper: RateAddress;
  recipient: RateAddress;
  packagingType: string;
  weight: Weight;
  dimensions: Dimensions;
  commodityDescription: string;
  commodityValue: number;
  currency: string;
}

interface PasswordData {
  currentPassword: string;
  newPassword: string;
}

interface IntegrationData {
  apiKey: string;
  issuanceDate: string;
  isActive: boolean;
  requestsCount: number;
  balance: number;
  hasApiKey: boolean;
}

export const authApi = {
  login: (credentials: Credentials) => api.post<{ token: string; user: UserData }>('/auth/login', credentials),
  signup: (userData: UserData) => api.post<{ token: string; user: UserData }>('/auth/signup', userData),
  logout: () => {
    localStorage.removeItem('token');
    return api.post('/auth/logout');
  },
  checkAuth: () => api.get<{ isAuthenticated: boolean; user: UserData | null }>('/auth/check'),
};

export const orderApi = {
  getOrders: () => api.get<OrderData[]>('/orders'),
  createOrder: (orderData: OrderData) => api.post<OrderData>('/orders', orderData),
  getOrder: (id: string) => api.get<OrderData>(`/orders/${id}`),
  updateOrder: (id: string, orderData: OrderData) => api.put<OrderData>(`/orders/${id}`, orderData),
  cancelOrder: (id: string) => api.delete(`/orders/${id}`),
  trackOrder: (trackingNumber: string) => api.get<ShipmentData>(`/orders/track/${trackingNumber}`),
};

export const notificationApi = {
  getNotifications: () => api.get<Array<{ id: string; message: string; read: boolean }>>('/notifications'),
  markAsRead: (id: string) => api.put(`/notifications/${id}/read`),
  getUnreadCount: () => api.get<{ count: number }>('/notifications/unread-count'),
};

export const accountApi = {
  getAccountInfo: () => api.get<UserData>('/account'),
  updateAccountInfo: (accountData: UserData) => api.put<UserData>('/account', accountData),
  changePassword: (passwordData: PasswordData) => api.put('/account/password', passwordData),
};

export const shipmentApi = {
  getShipment: (id: string) => api.get<ShipmentData>(`/shipments/${id}`),
  createShipment: (shipmentData: ShipmentData) => api.post<ShipmentData>('/shipments', shipmentData),
  updateShipment: (id: string, shipmentData: ShipmentData) => api.put<ShipmentData>(`/shipments/${id}`, shipmentData),
  calculateRates: (rateRequest: RateRequest) => api.post<Array<{ carrier: string; rate: number }>>('/commander/rate-calculation', rateRequest),
};

export const geocodingApi = {
  geocodeAddress: (address: string) => api.get<{ latitude: number; longitude: number }>(`/geocoding/geocode?address=${encodeURIComponent(address)}`),
};

export const integrationApi = {
  getIntegrationData: () => api.get<IntegrationData>('/integration/data'),
  createApiKey: () => api.post<IntegrationData>('/integration/create-api-key'),
  regenerateApiKey: () => api.post<IntegrationData>('/integration/regenerate-api-key'),
  createOrderWithApiKey: (orderData: OrderData, apiKey: string) => api.post<OrderData>('/integration/CreateOrder', orderData, {
    headers: {
      'X-API-Key': apiKey
    }
  }),
  trackOrderWithApiKey: (trackingNumber: string, apiKey: string) => api.get<ShipmentData>(`/orders/track/${trackingNumber}`, {
    headers: {
      'X-API-Key': apiKey
    }
  }),
};

export default api;